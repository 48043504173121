import { default as accessibiliteYcWL4gmlCgMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/accessibilite.vue?macro=true";
import { default as _91slug_93sju2dRHNloMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/activites/[slug].vue?macro=true";
import { default as editkgdCgVt90uMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/activites/[id]/edit.vue?macro=true";
import { default as indexXHzRhInxprMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/activites/[id]/index.vue?macro=true";
import { default as addEYxl2gkx2RMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/activites/add.vue?macro=true";
import { default as indexMoUx29lTd1Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/activites/index.vue?macro=true";
import { default as edithWWoMWXnJWMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/domaines/[id]/edit.vue?macro=true";
import { default as addGQon4lugAQMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/domaines/add.vue?macro=true";
import { default as indexeRVFVyBzgXMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/domaines/index.vue?macro=true";
import { default as editcMtd0hLim2Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/modeles-mission/[id]/edit.vue?macro=true";
import { default as addVMJU7lnlV9Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/modeles-mission/add.vue?macro=true";
import { default as indexpbK4iwxq8EMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/modeles-mission/index.vue?macro=true";
import { default as editn9qByVtPVBMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/reseaux/[id]/edit.vue?macro=true";
import { default as indexC3CICa2uNhMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/reseaux/[id]/index.vue?macro=true";
import { default as add9mJhuOD02nMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/reseaux/add.vue?macro=true";
import { default as indexAl0c2xe4fEMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/reseaux/index.vue?macro=true";
import { default as editobhV08u7p6Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/ressources/[id]/edit.vue?macro=true";
import { default as addQ8YauaCJ8fMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/ressources/add.vue?macro=true";
import { default as indexodIKJcdd8ZMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/ressources/index.vue?macro=true";
import { default as editnRousYTsG8Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/territoires/[id]/edit.vue?macro=true";
import { default as index7yX29H08duMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/territoires/[id]/index.vue?macro=true";
import { default as addb9whv1hWbeMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/territoires/add.vue?macro=true";
import { default as indexgRCVfZPQE9Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/territoires/index.vue?macro=true";
import { default as edit2f6On07DH0Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/testimonials/[id]/edit.vue?macro=true";
import { default as indexv55Vev1lmGMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/testimonials/index.vue?macro=true";
import { default as indexHSsP45oSuOMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/index.vue?macro=true";
import { default as benevoles_45informationsyrQhhI9mgKMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/benevoles-informations.vue?macro=true";
import { default as benevoles3jaN0v8TFcMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/benevoles.vue?macro=true";
import { default as datesyejCKn4PqxMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/dates.vue?macro=true";
import { default as indexyTdphh7HxwMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/index.vue?macro=true";
import { default as informationsndZlFdEXKNMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/informations.vue?macro=true";
import { default as lieuxDu48Ij5YwPMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/lieux.vue?macro=true";
import { default as responsablesZvGS1Q4Z9LMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/responsables.vue?macro=true";
import { default as titleXJEn5pf69GMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/title.vue?macro=true";
import { default as trouver_45des_45benevolesLDvG4rRohbMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/trouver-des-benevoles.vue?macro=true";
import { default as visueldqueuF5weYMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/visuel.vue?macro=true";
import { default as index6crblkfZvIMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/index.vue?macro=true";
import { default as notes8tCPwRpktrMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/notes.vue?macro=true";
import { default as editY37UcQScE0Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/organisations/[id]/edit.vue?macro=true";
import { default as index564kqcaYZNMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/organisations/[id]/index.vue?macro=true";
import { default as addnFzXJ3ggc8Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/organisations/[id]/missions/add.vue?macro=true";
import { default as indexhgxwlvccqcMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/organisations/index.vue?macro=true";
import { default as invitationsYn4lKM6K04Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/other/invitations.vue?macro=true";
import { default as logsG9H4paiUGqMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/other/logs.vue?macro=true";
import { default as indexFkyjTAjGy1Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/participations/index.vue?macro=true";
import { default as ressourcesdPsAWlxVJQMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/ressources.vue?macro=true";
import { default as emailsCamtTqJr3PMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/settings/emails.vue?macro=true";
import { default as general7l5r9EmgLVMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/settings/general.vue?macro=true";
import { default as messagesYyAEBMlqXOMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/settings/messages.vue?macro=true";
import { default as edituBeMRBWy5cMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/settings/rules/[id]/edit.vue?macro=true";
import { default as pending_45itemsgrX15B6bgSMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/settings/rules/[id]/pending-items.vue?macro=true";
import { default as addR1MsZdLzDPMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/settings/rules/add.vue?macro=true";
import { default as indexBb1D316iKAMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/settings/rules/index.vue?macro=true";
import { default as trafic_45entrantFQvV63dhAxMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/api-engagement/trafic-entrant.vue?macro=true";
import { default as trafic_45sortantaTJr7dZltqMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/api-engagement/trafic-sortant.vue?macro=true";
import { default as conversions8Os8ufLLRGMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/conversions.vue?macro=true";
import { default as missions_45a_45validerDzLzjwDCdvMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/departements/missions-a-valider.vue?macro=true";
import { default as missions_45perimees3zyJagE0ixMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/departements/missions-perimees.vue?macro=true";
import { default as organisations_45a_45validerRqNrh11UwhMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/departements/organisations-a-valider.vue?macro=true";
import { default as indexRMTc5tFkjLMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/index.vue?macro=true";
import { default as indicateurs_45clesKjVcGSA1WGMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/indicateurs-cles.vue?macro=true";
import { default as missionsDs4rTul7CRMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/missions.vue?macro=true";
import { default as indexBIfTkX7BQIMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/organisations/index.vue?macro=true";
import { default as missions_45perimeesdZOFEU8cfVMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/organisations/missions-perimees.vue?macro=true";
import { default as participations_45a_45validerLXeM3sS9ZPMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/organisations/participations-a-valider.vue?macro=true";
import { default as participations_45refusees_45annuleesF6pOQkoyl5Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/organisations/participations-refusees-annulees.vue?macro=true";
import { default as participationsVJvDep7yrJMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/participations.vue?macro=true";
import { default as placesKEaDPXQjS0Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/places.vue?macro=true";
import { default as moderationscuK8IG7NHuMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/suivis/moderations.vue?macro=true";
import { default as utilisateursL5NlQ16h2IMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/utilisateurs.vue?macro=true";
import { default as editzMItG8EPyBMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/taxonomies/[slug]/[id]/edit.vue?macro=true";
import { default as addIOhvCiygJmMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/taxonomies/[slug]/add.vue?macro=true";
import { default as indexjJ23bBcPD2Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/taxonomies/[slug]/index.vue?macro=true";
import { default as temoignagesGEQKflsHzoMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/temoignages.vue?macro=true";
import { default as editOjLNB1zVYQMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/utilisateurs/[id]/edit.vue?macro=true";
import { default as indexZcCmHUn810Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/utilisateurs/[id]/index.vue?macro=true";
import { default as index83uR98bEdXMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/utilisateurs/index.vue?macro=true";
import { default as benevoleszVIFTfuOBXMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/charte-bon-fonctionnement/benevoles.vue?macro=true";
import { default as indexTNWDRATuq4Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/charte-bon-fonctionnement/index.vue?macro=true";
import { default as responsablesmyfQnji0RQMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/charte-bon-fonctionnement/responsables.vue?macro=true";
import { default as charte_45reserve_45civiquewJwJq7aKHtMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/charte-reserve-civique.vue?macro=true";
import { default as conditions_45generales_45d_45utilisationg5E9BnA5OnMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/conditions-generales-d-utilisation.vue?macro=true";
import { default as indexNglEDNAzoSMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/dashboard/index.vue?macro=true";
import { default as _91slug_938yoUxJQ5HCMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/departements/[slug].vue?macro=true";
import { default as _91slug_93JuZXPe5fFfMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/domaines-action/[slug].vue?macro=true";
import { default as en_45ce_45moment2tXCxWUQKtMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/en-ce-moment.vue?macro=true";
import { default as indexIr8IWtCvVsMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/index.vue?macro=true";
import { default as indexTGvqxppmAOMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/benevole/index.vue?macro=true";
import { default as competences5L3ETkueGUMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/benevole/step/competences.vue?macro=true";
import { default as disponibilitesWUkChhgf1fMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/benevole/step/disponibilites.vue?macro=true";
import { default as preferencesxCo8GlA0adMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/benevole/step/preferences.vue?macro=true";
import { default as profilenfdLy0mHg5Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/benevole/step/profile.vue?macro=true";
import { default as indexVwfvOeQSeUMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/index.vue?macro=true";
import { default as indexJi71n6BrnXMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/index.vue?macro=true";
import { default as charte_45bon_45fonctionnementJkx8klJJCIMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/step/charte-bon-fonctionnement.vue?macro=true";
import { default as collectivite_45confirmationXuHrjmxar7Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/step/collectivite-confirmation.vue?macro=true";
import { default as collectivitesuMXJeSQHpMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/step/collectivite.vue?macro=true";
import { default as organisation_45confirmationO2AatKMJ4VMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/step/organisation-confirmation.vue?macro=true";
import { default as organisation_45details7bioO9K79sMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/step/organisation-details.vue?macro=true";
import { default as organisation_45imagesFzTXbZiPhnMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/step/organisation-images.vue?macro=true";
import { default as organisationwY2Sx1uUa0Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/step/organisation.vue?macro=true";
import { default as profileWW0opwMVQzMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/step/profile.vue?macro=true";
import { default as _91hash_93BNmJFzA5FtMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/invitations/[hash].vue?macro=true";
import { default as loginU7vdUahYYQMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/login.vue?macro=true";
import { default as adminDZLdNH66rnMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/maintenance/admin.vue?macro=true";
import { default as index4WcwXBI8xDMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/maintenance/index.vue?macro=true";
import { default as mentions_45legalesiwrIEtZte0Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/mentions-legales.vue?macro=true";
import { default as _91id_93TFMSqUC6yFMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/messages/[id].vue?macro=true";
import { default as indexhe3AVztgKbMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/messages/index.vue?macro=true";
import { default as addewLkkz65nsMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/messages/modeles/add.vue?macro=true";
import { default as indexZrXxWJ4ddZMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/messages/modeles/index.vue?macro=true";
import { default as _91slug_93n2SCVEvErNMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/missions-benevolat/[id]/[slug].vue?macro=true";
import { default as indexcscRlT2LXgMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/missions-benevolat/[id]/index.vue?macro=true";
import { default as index9BPmWKSOVTMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/missions-benevolat/index.vue?macro=true";
import { default as notificationsMfkJdH9SUWMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/notifications.vue?macro=true";
import { default as _91slug_93ZrVKl2h7bKMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/organisations/[slug].vue?macro=true";
import { default as indexTHv2aQrQG6Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/organisations/index.vue?macro=true";
import { default as _91token_939MewyPHgEiMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/password-reset/[token].vue?macro=true";
import { default as indexoCsoPpT7K4Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/password-reset/index.vue?macro=true";
import { default as plan_45du_45sitebXJyrAJxkRMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/plan-du-site.vue?macro=true";
import { default as politique_45de_45confidentialiteUkbo7VRNgOMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/politique-de-confidentialite.vue?macro=true";
import { default as charte_45bon_45fonctionnementIKucnFZJXSMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/profile/charte-bon-fonctionnement.vue?macro=true";
import { default as editpK40JfkYVWMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/profile/edit.vue?macro=true";
import { default as indexizgrQEiGGDMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/profile/index.vue?macro=true";
import { default as missionsujnWoRGqybMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/profile/missions.vue?macro=true";
import { default as notificationsq9jb1wPrbWMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/profile/notifications.vue?macro=true";
import { default as preferencescwALUhPu3RMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/profile/preferences.vue?macro=true";
import { default as settingsEibah6v8mCMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/profile/settings.vue?macro=true";
import { default as decembre_45ensemble5HfcCLzb3fMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/quiz/decembre-ensemble.vue?macro=true";
import { default as generiqueAxVd86E1WcMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/quiz/generique.vue?macro=true";
import { default as mgenOPRHq5s7zFMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/quiz/mgen.vue?macro=true";
import { default as printemps_45pour_45la_45planeteBc19CnmFjxMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/quiz/printemps-pour-la-planete.vue?macro=true";
import { default as septembre_45pour_45apprendrerSuaQHgQfOMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/quiz/septembre-pour-apprendre.vue?macro=true";
import { default as _91slug_93Bh6wZVZAkfMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/reseaux/[slug].vue?macro=true";
import { default as indexN9bTfiuJirMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/stats/index.vue?macro=true";
import { default as missionsxhwX7vfMRvMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/stats/missions.vue?macro=true";
import { default as organisationsfWGeQP5nYVMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/stats/organisations.vue?macro=true";
import { default as participationstQAcLIcAmUMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/stats/participations.vue?macro=true";
import { default as placesDQRHfaSpyfMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/stats/places.vue?macro=true";
import { default as utilisateursf6yMX8Y4XvMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/stats/utilisateurs.vue?macro=true";
import { default as search_45archived_45usersZUGUxaHc1eMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/actions/search-archived-users.vue?macro=true";
import { default as transfert_45organisationoi9w5XFxLHMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/actions/transfert-organisation.vue?macro=true";
import { default as user_45reset_45context_45role3ZLYuQ0bAqMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/actions/user-reset-context-role.vue?macro=true";
import { default as user_45reset_45password_45linkW6DKygLy52Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/actions/user-reset-password-link.vue?macro=true";
import { default as doublons_45organisationsbEs2UTE5DDMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/contents/doublons-organisations.vue?macro=true";
import { default as doublons_45territoiress9Fj91WwTgMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/contents/doublons-territoires.vue?macro=true";
import { default as indexhgyQqVucYrMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/index.vue?macro=true";
import { default as activity_45logsEIPb0b4Ad3Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/referents/activity-logs.vue?macro=true";
import { default as waiting_45actionsjOwwXdXLTgMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/referents/waiting-actions.vue?macro=true";
import { default as missions_45outdatedBZkgcUBnEhMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/responsables/missions-outdated.vue?macro=true";
import { default as participations_45to_45be_45treatede6Zg37T0saMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/responsables/participations-to-be-treated.vue?macro=true";
import { default as _91token_93X5jezC9J0JMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/temoignages/[token].vue?macro=true";
import { default as indexCK94LLDMLyMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/territoires/index.vue?macro=true";
import { default as _91slug_93igrFfFQtB9Meta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/villes/[slug].vue?macro=true";
import { default as component_45stub1O1K5js80wMeta } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub1O1K5js80w } from "/build/c5985649-e6d3-4175-9613-38e249c2f981/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "accessibilite",
    path: "/accessibilite",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/accessibilite.vue").then(m => m.default || m)
  },
  {
    name: "activites-slug",
    path: "/activites/:slug()",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/activites/[slug].vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-id-edit",
    path: "/admin/contenus/activites/:id()/edit",
    meta: editkgdCgVt90uMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/activites/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-id",
    path: "/admin/contenus/activites/:id()",
    meta: indexXHzRhInxprMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/activites/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-add",
    path: "/admin/contenus/activites/add",
    meta: addEYxl2gkx2RMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/activites/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites",
    path: "/admin/contenus/activites",
    meta: indexMoUx29lTd1Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/activites/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines-id-edit",
    path: "/admin/contenus/domaines/:id()/edit",
    meta: edithWWoMWXnJWMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/domaines/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines-add",
    path: "/admin/contenus/domaines/add",
    meta: addGQon4lugAQMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/domaines/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines",
    path: "/admin/contenus/domaines",
    meta: indexeRVFVyBzgXMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/domaines/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission-id-edit",
    path: "/admin/contenus/modeles-mission/:id()/edit",
    meta: editcMtd0hLim2Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/modeles-mission/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission-add",
    path: "/admin/contenus/modeles-mission/add",
    meta: addVMJU7lnlV9Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/modeles-mission/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission",
    path: "/admin/contenus/modeles-mission",
    meta: indexpbK4iwxq8EMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/modeles-mission/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-id-edit",
    path: "/admin/contenus/reseaux/:id()/edit",
    meta: editn9qByVtPVBMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/reseaux/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-id",
    path: "/admin/contenus/reseaux/:id()",
    meta: indexC3CICa2uNhMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/reseaux/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-add",
    path: "/admin/contenus/reseaux/add",
    meta: add9mJhuOD02nMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/reseaux/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux",
    path: "/admin/contenus/reseaux",
    meta: indexAl0c2xe4fEMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/reseaux/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources-id-edit",
    path: "/admin/contenus/ressources/:id()/edit",
    meta: editobhV08u7p6Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/ressources/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources-add",
    path: "/admin/contenus/ressources/add",
    meta: addQ8YauaCJ8fMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/ressources/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources",
    path: "/admin/contenus/ressources",
    meta: indexodIKJcdd8ZMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/ressources/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-id-edit",
    path: "/admin/contenus/territoires/:id()/edit",
    meta: editnRousYTsG8Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/territoires/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-id",
    path: "/admin/contenus/territoires/:id()",
    meta: index7yX29H08duMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/territoires/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-add",
    path: "/admin/contenus/territoires/add",
    meta: addb9whv1hWbeMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/territoires/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires",
    path: "/admin/contenus/territoires",
    meta: indexgRCVfZPQE9Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/territoires/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-testimonials-id-edit",
    path: "/admin/contenus/testimonials/:id()/edit",
    meta: edit2f6On07DH0Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/testimonials/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-testimonials",
    path: "/admin/contenus/testimonials",
    meta: indexv55Vev1lmGMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/contenus/testimonials/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexHSsP45oSuOMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-benevoles-informations",
    path: "/admin/missions/:id()/benevoles-informations",
    meta: benevoles_45informationsyrQhhI9mgKMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/benevoles-informations.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-benevoles",
    path: "/admin/missions/:id()/benevoles",
    meta: benevoles3jaN0v8TFcMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/benevoles.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-dates",
    path: "/admin/missions/:id()/dates",
    meta: datesyejCKn4PqxMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/dates.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id",
    path: "/admin/missions/:id()",
    meta: indexyTdphh7HxwMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-informations",
    path: "/admin/missions/:id()/informations",
    meta: informationsndZlFdEXKNMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/informations.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-lieux",
    path: "/admin/missions/:id()/lieux",
    meta: lieuxDu48Ij5YwPMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/lieux.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-responsables",
    path: "/admin/missions/:id()/responsables",
    meta: responsablesZvGS1Q4Z9LMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/responsables.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-title",
    path: "/admin/missions/:id()/title",
    meta: titleXJEn5pf69GMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/title.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-trouver-des-benevoles",
    path: "/admin/missions/:id()/trouver-des-benevoles",
    meta: trouver_45des_45benevolesLDvG4rRohbMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/trouver-des-benevoles.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-visuel",
    path: "/admin/missions/:id()/visuel",
    meta: visueldqueuF5weYMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/[id]/visuel.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions",
    path: "/admin/missions",
    meta: index6crblkfZvIMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/missions/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-notes",
    path: "/admin/notes",
    meta: notes8tCPwRpktrMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/notes.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id-edit",
    path: "/admin/organisations/:id()/edit",
    meta: editY37UcQScE0Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/organisations/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id",
    path: "/admin/organisations/:id()",
    meta: index564kqcaYZNMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/organisations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id-missions-add",
    path: "/admin/organisations/:id()/missions/add",
    meta: addnFzXJ3ggc8Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/organisations/[id]/missions/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations",
    path: "/admin/organisations",
    meta: indexhgxwlvccqcMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-other-invitations",
    path: "/admin/other/invitations",
    meta: invitationsYn4lKM6K04Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/other/invitations.vue").then(m => m.default || m)
  },
  {
    name: "admin-other-logs",
    path: "/admin/other/logs",
    meta: logsG9H4paiUGqMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/other/logs.vue").then(m => m.default || m)
  },
  {
    name: "admin-participations",
    path: "/admin/participations",
    meta: indexFkyjTAjGy1Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/participations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-ressources",
    path: "/admin/ressources",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/ressources.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-emails",
    path: "/admin/settings/emails",
    meta: emailsCamtTqJr3PMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/settings/emails.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-general",
    path: "/admin/settings/general",
    meta: general7l5r9EmgLVMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/settings/general.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-messages",
    path: "/admin/settings/messages",
    meta: messagesYyAEBMlqXOMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/settings/messages.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-id-edit",
    path: "/admin/settings/rules/:id()/edit",
    meta: edituBeMRBWy5cMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/settings/rules/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-id-pending-items",
    path: "/admin/settings/rules/:id()/pending-items",
    meta: pending_45itemsgrX15B6bgSMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/settings/rules/[id]/pending-items.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-add",
    path: "/admin/settings/rules/add",
    meta: addR1MsZdLzDPMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/settings/rules/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules",
    path: "/admin/settings/rules",
    meta: indexBb1D316iKAMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/settings/rules/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-api-engagement-trafic-entrant",
    path: "/admin/statistics/api-engagement/trafic-entrant",
    meta: trafic_45entrantFQvV63dhAxMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/api-engagement/trafic-entrant.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-api-engagement-trafic-sortant",
    path: "/admin/statistics/api-engagement/trafic-sortant",
    meta: trafic_45sortantaTJr7dZltqMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/api-engagement/trafic-sortant.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-conversions",
    path: "/admin/statistics/conversions",
    meta: conversions8Os8ufLLRGMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/conversions.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-missions-a-valider",
    path: "/admin/statistics/departements/missions-a-valider",
    meta: missions_45a_45validerDzLzjwDCdvMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/departements/missions-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-missions-perimees",
    path: "/admin/statistics/departements/missions-perimees",
    meta: missions_45perimees3zyJagE0ixMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/departements/missions-perimees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-organisations-a-valider",
    path: "/admin/statistics/departements/organisations-a-valider",
    meta: organisations_45a_45validerRqNrh11UwhMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/departements/organisations-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics",
    path: "/admin/statistics",
    meta: indexRMTc5tFkjLMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-indicateurs-cles",
    path: "/admin/statistics/indicateurs-cles",
    meta: indicateurs_45clesKjVcGSA1WGMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/indicateurs-cles.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-missions",
    path: "/admin/statistics/missions",
    meta: missionsDs4rTul7CRMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/missions.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations",
    path: "/admin/statistics/organisations",
    meta: indexBIfTkX7BQIMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-missions-perimees",
    path: "/admin/statistics/organisations/missions-perimees",
    meta: missions_45perimeesdZOFEU8cfVMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/organisations/missions-perimees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-participations-a-valider",
    path: "/admin/statistics/organisations/participations-a-valider",
    meta: participations_45a_45validerLXeM3sS9ZPMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/organisations/participations-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-participations-refusees-annulees",
    path: "/admin/statistics/organisations/participations-refusees-annulees",
    meta: participations_45refusees_45annuleesF6pOQkoyl5Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/organisations/participations-refusees-annulees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-participations",
    path: "/admin/statistics/participations",
    meta: participationsVJvDep7yrJMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/participations.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-places",
    path: "/admin/statistics/places",
    meta: placesKEaDPXQjS0Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/places.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-suivis-moderations",
    path: "/admin/statistics/suivis/moderations",
    meta: moderationscuK8IG7NHuMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/suivis/moderations.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-utilisateurs",
    path: "/admin/statistics/utilisateurs",
    meta: utilisateursL5NlQ16h2IMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/statistics/utilisateurs.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug-id-edit",
    path: "/admin/taxonomies/:slug()/:id()/edit",
    meta: editzMItG8EPyBMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/taxonomies/[slug]/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug-add",
    path: "/admin/taxonomies/:slug()/add",
    meta: addIOhvCiygJmMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/taxonomies/[slug]/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug",
    path: "/admin/taxonomies/:slug()",
    meta: indexjJ23bBcPD2Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/taxonomies/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-temoignages",
    path: "/admin/temoignages",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/temoignages.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs-id-edit",
    path: "/admin/utilisateurs/:id()/edit",
    meta: editOjLNB1zVYQMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/utilisateurs/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs-id",
    path: "/admin/utilisateurs/:id()",
    meta: indexZcCmHUn810Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/utilisateurs/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs",
    path: "/admin/utilisateurs",
    meta: index83uR98bEdXMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/admin/utilisateurs/index.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement-benevoles",
    path: "/charte-bon-fonctionnement/benevoles",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/charte-bon-fonctionnement/benevoles.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement",
    path: "/charte-bon-fonctionnement",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/charte-bon-fonctionnement/index.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement-responsables",
    path: "/charte-bon-fonctionnement/responsables",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/charte-bon-fonctionnement/responsables.vue").then(m => m.default || m)
  },
  {
    name: "charte-reserve-civique",
    path: "/charte-reserve-civique",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/charte-reserve-civique.vue").then(m => m.default || m)
  },
  {
    name: "conditions-generales-d-utilisation",
    path: "/conditions-generales-d-utilisation",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/conditions-generales-d-utilisation.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexNglEDNAzoSMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "departements-slug",
    path: "/departements/:slug()",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/departements/[slug].vue").then(m => m.default || m)
  },
  {
    name: "domaines-action-slug",
    path: "/domaines-action/:slug()",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/domaines-action/[slug].vue").then(m => m.default || m)
  },
  {
    name: "en-ce-moment",
    path: "/en-ce-moment",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/en-ce-moment.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole",
    path: "/inscription/benevole",
    meta: indexTGvqxppmAOMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/benevole/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-competences",
    path: "/inscription/benevole/step/competences",
    meta: competences5L3ETkueGUMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/benevole/step/competences.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-disponibilites",
    path: "/inscription/benevole/step/disponibilites",
    meta: disponibilitesWUkChhgf1fMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/benevole/step/disponibilites.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-preferences",
    path: "/inscription/benevole/step/preferences",
    meta: preferencesxCo8GlA0adMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/benevole/step/preferences.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-profile",
    path: "/inscription/benevole/step/profile",
    meta: profilenfdLy0mHg5Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/benevole/step/profile.vue").then(m => m.default || m)
  },
  {
    name: "inscription",
    path: "/inscription",
    meta: indexVwfvOeQSeUMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable",
    path: "/inscription/responsable",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-charte-bon-fonctionnement",
    path: "/inscription/responsable/step/charte-bon-fonctionnement",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/step/charte-bon-fonctionnement.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-collectivite-confirmation",
    path: "/inscription/responsable/step/collectivite-confirmation",
    meta: collectivite_45confirmationXuHrjmxar7Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/step/collectivite-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-collectivite",
    path: "/inscription/responsable/step/collectivite",
    meta: collectivitesuMXJeSQHpMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/step/collectivite.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-confirmation",
    path: "/inscription/responsable/step/organisation-confirmation",
    meta: organisation_45confirmationO2AatKMJ4VMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/step/organisation-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-details",
    path: "/inscription/responsable/step/organisation-details",
    meta: organisation_45details7bioO9K79sMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/step/organisation-details.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-images",
    path: "/inscription/responsable/step/organisation-images",
    meta: organisation_45imagesFzTXbZiPhnMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/step/organisation-images.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation",
    path: "/inscription/responsable/step/organisation",
    meta: organisationwY2Sx1uUa0Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/step/organisation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-profile",
    path: "/inscription/responsable/step/profile",
    meta: profileWW0opwMVQzMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/inscription/responsable/step/profile.vue").then(m => m.default || m)
  },
  {
    name: "invitations-hash",
    path: "/invitations/:hash()",
    meta: _91hash_93BNmJFzA5FtMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/invitations/[hash].vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginU7vdUahYYQMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "maintenance-admin",
    path: "/maintenance/admin",
    meta: adminDZLdNH66rnMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/maintenance/admin.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: index4WcwXBI8xDMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/maintenance/index.vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "messages-id",
    path: "/messages/:id()",
    meta: _91id_93TFMSqUC6yFMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: "messages",
    path: "/messages",
    meta: indexhe3AVztgKbMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "messages-modeles-add",
    path: "/messages/modeles/add",
    meta: addewLkkz65nsMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/messages/modeles/add.vue").then(m => m.default || m)
  },
  {
    name: "messages-modeles",
    path: "/messages/modeles",
    meta: indexZrXxWJ4ddZMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/messages/modeles/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat-id-slug",
    path: "/missions-benevolat/:id()/:slug()",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/missions-benevolat/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat-id",
    path: "/missions-benevolat/:id()",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/missions-benevolat/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat",
    path: "/missions-benevolat",
    meta: index9BPmWKSOVTMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/missions-benevolat/index.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notificationsMfkJdH9SUWMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: "organisations-slug",
    path: "/organisations/:slug()",
    meta: _91slug_93ZrVKl2h7bKMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/organisations/[slug].vue").then(m => m.default || m)
  },
  {
    name: "organisations",
    path: "/organisations",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password-reset/:token()",
    meta: _91token_939MewyPHgEiMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: indexoCsoPpT7K4Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-du-site",
    path: "/plan-du-site",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/plan-du-site.vue").then(m => m.default || m)
  },
  {
    name: "politique-de-confidentialite",
    path: "/politique-de-confidentialite",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/politique-de-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: "profile-charte-bon-fonctionnement",
    path: "/profile/charte-bon-fonctionnement",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/profile/charte-bon-fonctionnement.vue").then(m => m.default || m)
  },
  {
    name: "profile-edit",
    path: "/profile/edit",
    meta: editpK40JfkYVWMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexizgrQEiGGDMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-missions",
    path: "/profile/missions",
    meta: missionsujnWoRGqybMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/profile/missions.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: notificationsq9jb1wPrbWMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "profile-preferences",
    path: "/profile/preferences",
    meta: preferencescwALUhPu3RMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/profile/preferences.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: settingsEibah6v8mCMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "quiz-decembre-ensemble",
    path: "/quiz/decembre-ensemble",
    meta: decembre_45ensemble5HfcCLzb3fMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/quiz/decembre-ensemble.vue").then(m => m.default || m)
  },
  {
    name: "quiz-generique",
    path: "/quiz/generique",
    meta: generiqueAxVd86E1WcMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/quiz/generique.vue").then(m => m.default || m)
  },
  {
    name: "quiz-mgen",
    path: "/quiz/mgen",
    meta: mgenOPRHq5s7zFMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/quiz/mgen.vue").then(m => m.default || m)
  },
  {
    name: "quiz-printemps-pour-la-planete",
    path: "/quiz/printemps-pour-la-planete",
    meta: printemps_45pour_45la_45planeteBc19CnmFjxMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/quiz/printemps-pour-la-planete.vue").then(m => m.default || m)
  },
  {
    name: "quiz-septembre-pour-apprendre",
    path: "/quiz/septembre-pour-apprendre",
    meta: septembre_45pour_45apprendrerSuaQHgQfOMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/quiz/septembre-pour-apprendre.vue").then(m => m.default || m)
  },
  {
    name: "reseaux-slug",
    path: "/reseaux/:slug()",
    meta: _91slug_93Bh6wZVZAkfMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/reseaux/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stats",
    path: "/stats",
    meta: indexN9bTfiuJirMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/stats/index.vue").then(m => m.default || m)
  },
  {
    name: "stats-missions",
    path: "/stats/missions",
    meta: missionsxhwX7vfMRvMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/stats/missions.vue").then(m => m.default || m)
  },
  {
    name: "stats-organisations",
    path: "/stats/organisations",
    meta: organisationsfWGeQP5nYVMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/stats/organisations.vue").then(m => m.default || m)
  },
  {
    name: "stats-participations",
    path: "/stats/participations",
    meta: participationstQAcLIcAmUMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/stats/participations.vue").then(m => m.default || m)
  },
  {
    name: "stats-places",
    path: "/stats/places",
    meta: placesDQRHfaSpyfMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/stats/places.vue").then(m => m.default || m)
  },
  {
    name: "stats-utilisateurs",
    path: "/stats/utilisateurs",
    meta: utilisateursf6yMX8Y4XvMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/stats/utilisateurs.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-search-archived-users",
    path: "/support/actions/search-archived-users",
    meta: search_45archived_45usersZUGUxaHc1eMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/actions/search-archived-users.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-transfert-organisation",
    path: "/support/actions/transfert-organisation",
    meta: transfert_45organisationoi9w5XFxLHMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/actions/transfert-organisation.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-user-reset-context-role",
    path: "/support/actions/user-reset-context-role",
    meta: user_45reset_45context_45role3ZLYuQ0bAqMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/actions/user-reset-context-role.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-user-reset-password-link",
    path: "/support/actions/user-reset-password-link",
    meta: user_45reset_45password_45linkW6DKygLy52Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/actions/user-reset-password-link.vue").then(m => m.default || m)
  },
  {
    name: "support-contents-doublons-organisations",
    path: "/support/contents/doublons-organisations",
    meta: doublons_45organisationsbEs2UTE5DDMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/contents/doublons-organisations.vue").then(m => m.default || m)
  },
  {
    name: "support-contents-doublons-territoires",
    path: "/support/contents/doublons-territoires",
    meta: doublons_45territoiress9Fj91WwTgMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/contents/doublons-territoires.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    meta: indexhgyQqVucYrMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "support-referents-activity-logs",
    path: "/support/referents/activity-logs",
    meta: activity_45logsEIPb0b4Ad3Meta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/referents/activity-logs.vue").then(m => m.default || m)
  },
  {
    name: "support-referents-waiting-actions",
    path: "/support/referents/waiting-actions",
    meta: waiting_45actionsjOwwXdXLTgMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/referents/waiting-actions.vue").then(m => m.default || m)
  },
  {
    name: "support-responsables-missions-outdated",
    path: "/support/responsables/missions-outdated",
    meta: missions_45outdatedBZkgcUBnEhMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/responsables/missions-outdated.vue").then(m => m.default || m)
  },
  {
    name: "support-responsables-participations-to-be-treated",
    path: "/support/responsables/participations-to-be-treated",
    meta: participations_45to_45be_45treatede6Zg37T0saMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/support/responsables/participations-to-be-treated.vue").then(m => m.default || m)
  },
  {
    name: "temoignages-token",
    path: "/temoignages/:token()",
    meta: _91token_93X5jezC9J0JMeta || {},
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/temoignages/[token].vue").then(m => m.default || m)
  },
  {
    name: "territoires",
    path: "/territoires",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/territoires/index.vue").then(m => m.default || m)
  },
  {
    name: "villes-slug",
    path: "/villes/:slug()",
    component: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/pages/villes/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/register/volontaire/:pathMatch(.*)",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/register/responsable/:pathMatch(.*)",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/user/settings",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/user/:pathMatch(.*)",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/dashboard/structure/:pathMatch(.*)",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/dashboard/mission/:pathMatch(.*)",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/dashboard/participation/:pathMatch(.*)",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/dashboard/profile/:pathMatch(.*)",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/dashboard/reseaux/:pathMatch(.*)",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/inscription/organisation",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/statistiques",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/activites/accompagnement-aux-activites-sportives",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/pages-sitemap.xml",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/missions-sitemap.xml",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/organisations-sitemap.xml",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/villes-sitemap.xml",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/departements-sitemap.xml",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/reseaux-sitemap.xml",
    component: component_45stub1O1K5js80w
  },
  {
    name: component_45stub1O1K5js80wMeta?.name,
    path: "/activites-sitemap.xml",
    component: component_45stub1O1K5js80w
  }
]