import validate from "/build/c5985649-e6d3-4175-9613-38e249c2f981/node_modules/nuxt/dist/pages/runtime/validate.js";
import maintenance_45global from "/build/c5985649-e6d3-4175-9613-38e249c2f981/middleware/maintenance.global.ts";
import manifest_45route_45rule from "/build/c5985649-e6d3-4175-9613-38e249c2f981/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  maintenance_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/middleware/admin.ts"),
  "agreed-responsable-terms": () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/middleware/agreed-responsable-terms.ts"),
  authenticated: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/middleware/authenticated.ts"),
  guest: () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/middleware/guest.ts"),
  "old-search-redirections": () => import("/build/c5985649-e6d3-4175-9613-38e249c2f981/middleware/old-search-redirections.ts")
}